import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_statistic = _resolveComponent("el-statistic")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_statistic, {
              title: "总人数",
              value: _ctx.sumUserCount,
              "value-style": _ctx.withCol
            }, null, 8, ["value", "value-style"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_statistic, {
              title: "本月活跃人数",
              value: _ctx.sumLoginCount,
              "value-style": _ctx.withCol
            }, null, 8, ["value", "value-style"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_statistic, {
              title: "本月总充值",
              value: _ctx.sumChongzhi,
              "value-style": _ctx.withCol
            }, null, 8, ["value", "value-style"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_statistic, {
              title: "本月总赠送",
              value: _ctx.sumZengsong,
              "value-style": _ctx.withCol
            }, null, 8, ["value", "value-style"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_statistic, {
              title: "本月新增人数",
              value: _ctx.sumRegCount,
              "value-style": _ctx.withCol
            }, null, 8, ["value", "value-style"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userGroup, (data) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            span: 3,
            key: data
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_statistic, {
                title: data.province,
                value: data.count,
                "value-style": _ctx.withCol
              }, null, 8, ["title", "value", "value-style"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}