
import { client } from "@/client";
import { ProvinceTopData } from "@/shared/types/UserWechatData";
import { defineComponent } from "vue";
export default defineComponent({
  name: "home-info",
  data: () => {
    return {
      withCol:"font-size:45px",
      userGroup:[{count:'2',province:"河南"}] as ProvinceTopData[],
      sumUserCount:0,
      sumChongzhi:0,
      sumZengsong:0,
      sumRegCount:0,
      sumLoginCount:0,
      data: {
        month: 0,
        quarter: 0,
        today: 0,
        week: 0,
        year: 0,
        yesterday: 0,
        message: 0,
      },
      dirname: "",
      loading: true,
    };
  },
  computed: {},
  // created() {},
  mounted() {
    // this.tongji();
    this.runEnv();
  },

  methods: {
    // async tongji() {
    //   try {
    //     let res = await tongji();
    //     if (res.code === 200) {
    //       this.data = res.data;
    //       this.loading = false;
    //       this.articleNum();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async runEnv() {
        let res = await client.callApi("site/RunEnv", {});
        if (res.isSucc) {
          let data = res.res;
          this.dirname = data.dirname;
          this.sumUserCount = data.sumUserCount;
          this.sumChongzhi = data.sumChongzhi;
          this.sumZengsong = data.sumZengsong;
          this.sumRegCount = data.sumRegCount;
          this.sumLoginCount = data.sumLoginCount;
        }
        let ret = await client.callApi("user/Group", {});
        if (ret.isSucc) {
          this.userGroup = ret.res.list;
          // console.error(ret)
        }
    },

    // articleNum() {
    //   console.log(this.data)
      // const data = [
      //   {
      //     type: "昨日",
      //     num: this.data.yesterday,
      //   },
      //   {
      //     type: "今天",
      //     sales: this.data.today,
      //   },
      //   {
      //     type: "7天",
      //     sales: this.data.week,
      //   },
      //   {
      //     type: "30天",
      //     sales: this.data.month,
      //   },
      //   {
      //     type: "一个季度",
      //     sales: this.data.quarter,
      //   },
      //   {
      //     type: "一年",
      //     sales: this.data.year,
      //   },
      //   {
      //     type: "留言总数",
      //     sales: this.data.message,
      //   },
      // ];
    // }
  },
});
